declare const daum: any;
type callBackType = (addressObj: { [index: string]: string }) => void;

// 주소 검색 후 도출된 데이터를 객체형태로 콜백함수 파라미터로 보내주는 함수
export const popupAddressForm = (callback: callBackType) => {
  const width = 500; //팝업의 너비
  const height = 500; //팝업의 높이
  new daum.Postcode({
    oncomplete: function (data: { [index: string]: string }) {
      callback(data);
    },
    width: width, //생성자에 크기 값을 명시적으로 지정해야 합니다.
    height: height,
  }).open({
    left: window.screen.width / 2 - width / 2,
    top: window.screen.height / 2 - height / 2,
    popupName: "postcodePopup",
  });
};

export const embedAddressForm = (element: any, callback: callBackType) => {
  new daum.Postcode({
    oncomplete: function (data: { [index: string]: string }) {
      callback(data);
    },
    width: "100%",
    height: "100%",
  }).embed(element);
};
