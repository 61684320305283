





















import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  props: {
    // TODO: 결제 방식 네이밍 정해지면 바꾸기
    paymentMethod: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const paymentMethodText = computed(() => {
      switch (props.paymentMethod) {
        case "CARD":
          return "신용카드";
        case "ACCOUNT":
          return "무통장 입금";
        case "CELLPHONE":
          return "휴대폰 결제";
        case "NAVERPAY_AUTH":
          return "네이버페이";
        default:
          return "";
      }
    });

    const refundMethodText = computed(() => {
      switch (props.paymentMethod) {
        case "CARD":
          return "신용카드 승인 취소";
        case "ACCOUNT":
          return "계좌입금";
        case "CELLPHONE":
          return "휴대폰 결제 취소";
        case "NAVERPAY_AUTH":
          return "네이버페이 결제 취소";
        default:
          return "";
      }
    });

    const refundDetailList = {
      CARD: [
        "취소완료 후, 3~5 영업일 이내 카드 승인 취소됩니다.\n단, 상품이 택배사로 이미 발송된 경우 상품회수 완료 후 환불",
      ],
      ACCOUNT: [
        "취소완료 후, 입력하신 환불계좌로 1~2 영업일 이내내 환불금액 입금됩니다.\n단, 상품이 택배사로 이미 발송된 경우 상품회수 완료 후 환불",
      ],
      CELLPHONE: [
        "당월 구매내역 취소 시: 다음 달 휴대폰 요금에 청구되지 않도록 승인 취소됩니다.",
        "전월 구매내역 취소 시: 취소완료 후, 입력하신 환불계좌로 1~2 영업일 이내 환불금액 입금됩니다.",
        "단, 상품이 택배사로 이미 발송된 경우 상품회수 완료 후 환불",
      ],
      NAVERPAY_AUTH: [
        "카드 결제 시: 이용했던 결제수단에 따라 3~5 영업일 이내 카드 승인 취소됩니다.",
        "카드 이외 결제 수단 시: 취소완료 후, 1~2 영업일 이내 환불금액 승인 취소됩니다.",
        "단, 상품이 택배사로 이미 발송된 경우 상품회수 완료 후 환불",
      ],
    };

    return {
      paymentMethodText,
      refundMethodText,
      refundDetailList,
    };
  },
});
