





















import { defineComponent } from "@vue/composition-api";

// component
import SearchFormByOrderNo from "./SearchFormByOrderNo.vue";
import SearchFormByPhoneNo from "./SearchFormByPhoneNo.vue";

// type
import { SearchMethod } from "@/views/Delivery.vue";

export default defineComponent({
  components: { SearchFormByOrderNo, SearchFormByPhoneNo },
  props: {
    shopUrl: {
      type: String,
      required: true,
    },
    orderGroupNo: {
      type: String,
    },
    shopperName: {
      type: String,
    },
    searchMethod: {
      type: String as () => SearchMethod,
      default: "",
    },
  },
  emits: [
    "input:orderGruopNo",
    "input:shopperName",
    "searchDone",
    "changeSearchMethod",
  ],
});
