import axios from "axios";

export const SLACK_WEBHOOK_URL = {
  LOG_DEBUG: `${process.env.VUE_APP_SLACK_WEBHOOK_LOG_DEBUG}`,
};

export const sendSlackWebHook = async (url: string, params: any) => {
  await axios.post(url, params, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};
