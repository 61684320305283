





























































































import { defineComponent } from "@vue/composition-api";

// Components
import OrderPreview from "../Common/OrderPreview.vue";

// Hooks
import formatNumber from "../Hooks/formatNumber";
import makeOrderState from "@/components/Delivery/Hooks/makeOrderState";

// Types
import { Order, OrderByProduct, OrderOption, OrderState } from "./Types";
import { OrderPreviewInfo } from "../Product/Types";

export default defineComponent({
  components: { OrderPreview },
  props: {
    productList: {
      type: Array as () => OrderByProduct[],
      required: true,
    },
  },
  emits: [
    "click:cancel",
    "destinationChange",
    "deliveryTracking",
    "requestTakeback",
  ],
  setup() {
    // orderState에 따른 유저 액션 허용 영역 정의(cancel / search / refund / none)
    const makeActionMode = (state: OrderState) => {
      switch (state) {
        case "REGISTER":
          return "cancel";
        case "ORDERED":
          return "cancel";
        case "PAID":
          return "cancel";
        case "READY":
          return "search";
        case "DELIVERY":
          return "search";
        case "DONE":
          return "refund";
        default:
          return "none";
      }
    };

    const makeSelectedOption = (optionText: OrderOption[]) => {
      const previewOptions: string[] = [];
      optionText.forEach((option) => {
        let text = option.content;
        if (option.addPrice) {
          text += `${text}(+${formatNumber(option.addPrice)})`;
        }
        previewOptions.push(text);
      });
      return previewOptions;
    };

    const makeOrderPreviewInfo = (order: Order) => {
      const previewInfo: OrderPreviewInfo = {
        productName: order.productName,
        price: order.amount + order.vat,
        marketPrice: null,
        thumbnailImg: order.productThumbImg,
        quantity: order.qty,
        selectedOptions: makeSelectedOption(order.optionText),
      };
      return previewInfo;
    };

    return {
      makeActionMode,
      makeOrderState,
      makeOrderPreviewInfo,
    };
  },
});
