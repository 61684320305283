











































// types
import { DeliveryInfo } from "@/components/Delivery/Types";

import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  props: {
    deliveryInfo: {
      type: Object as () => DeliveryInfo,
      required: true,
    },
  },
});
