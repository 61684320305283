/* moment.js */
import moment from "moment";

export default function makeTime(
  state: string,
  fullTime: string,
  locale: string
) {
  // utc 시간
  const utcTime = moment.utc(fullTime).format();
  // local 시간(한국 or 국제)
  let localTime: any;
  if (locale === "ko") {
    localTime = moment(utcTime).locale("ko");
  } else {
    localTime = moment(utcTime).locale("en");
  }

  return localTime.format(state);
}
