import { onMounted, ref, computed } from "@vue/composition-api";
import axios from "axios";

import { useI18n } from "@/libs/i18n";

export default (companyName: string | undefined) => {
  const { t } = useI18n();

  const apiKey = process.env.VUE_APP_SWEETTRACKER_KEY;
  const sweetTrackerUrl = "https://info.sweettracker.co.kr/tracking/5";

  const companyList = ref<{ name: string; id: string }[]>([]);
  const companyCode = computed<string | null>(() => {
    if (companyList.value.length === 0 || companyName == null) {
      return null;
    }

    const company = companyList.value.find(
      (element) => element.name === companyName
    );

    if (company) {
      return company.id;
    }

    return null;
  });

  const requestShipmentCompanyList = async () => {
    const res = await axios.get(
      `${process.env.VUE_APP_BACKEND_SERVER}/delivery/shipment-company/list/`
    );

    return res.data.data;
  };

  onMounted(async () => {
    try {
      const data = await requestShipmentCompanyList();

      companyList.value = data;
    } catch {
      alert(t("error.loadSweetTrackerCode"));
    }
  });

  const formEl = ref();

  const submit = () => {
    if (companyCode.value == null) {
      return;
    }
    formEl.value.submit();
  };

  return {
    companyList,
    apiKey,
    sweetTrackerUrl,
    companyCode,
    formEl,
    submit,
  };
};
