











































import { defineComponent, ref, computed } from "@vue/composition-api";

export default defineComponent({
  props: {
    orderGroupNo: {
      type: String,
    },
    shopperName: {
      type: String,
    },
  },
  emits: [
    "changeSearchMethod",
    "submitOrderNo",
    "input:orderGroupNo",
    "input:shopperName",
  ],
  setup(props, context) {
    const isFullData = computed(
      () => props.orderGroupNo !== "" && props.shopperName !== ""
    );

    const submitForm = async () => {
      if (!isFullData.value) {
        alert("주문번호와 수령인을 입력해주세요.");
        return;
      }
      context.emit("submitOrderNo");
    };
    return {
      isFullData,
      submitForm,
    };
  },
});
