










































































































































































































// types
import { DeliveryInfo } from "@/components/Delivery/Types";

// library
import { embedAddressForm } from "@/components/Address/DaumPostcode";

// components
import DestinationInfo from "@/components/Delivery/DestinationInfo.vue";
import MainTextInput from "@/components/Common/MainTextInput.vue";
import MainTextInputIcon from "@/components/Common/MainTextInputIcon.vue";
import SearchIcon from "@/assets/ElementsImage/SearchIcon.vue";
import ArrowDownIcon from "@/assets/ElementsImage/Arrow-Down.vue";
import CloseIcon from "@/assets/ElementsImage/CloseIcon.vue";
import BottomFixedModal from "@/components/Common/BottomFixedModal.vue";

import {
  defineComponent,
  ref,
  computed,
  nextTick,
  watch,
  onBeforeUnmount,
  reactive,
} from "@vue/composition-api";

export default defineComponent({
  components: {
    DestinationInfo,
    MainTextInput,
    MainTextInputIcon,
    SearchIcon,
    ArrowDownIcon,
    CloseIcon,
    BottomFixedModal,
  },
  props: {
    deliveryInfo: {
      type: Object as () => DeliveryInfo,
      required: true,
    },
  },
  emits: ["requestChange"],
  setup(props, context) {
    const newDeliveryInfo = reactive({
      address: "",
      addressDetail: "",
      message: "",
      phone: "",
      recipientName: "",
      zipcode: "",
      personalCustomsId: "",
    });

    const isFullData = computed(() => {
      let isFull =
        newDeliveryInfo.address &&
        newDeliveryInfo.addressDetail &&
        newDeliveryInfo.phone &&
        newDeliveryInfo.recipientName &&
        newDeliveryInfo.zipcode;

      if (props.deliveryInfo.personalCustomsId) {
        isFull = isFull && newDeliveryInfo.personalCustomsId;
      }

      return isFull;
    });

    const addressSearchContainerEl = ref();
    const isAddressSearchContainerVisible = ref(false);

    const fillAddressForm = (addressObj: { [key: string]: string }) => {
      newDeliveryInfo.zipcode = addressObj.zonecode;
      newDeliveryInfo.address = addressObj.address;
      isAddressSearchContainerVisible.value = false;
      // console.log(addressObj.zonecode, addressObj.address);
    };

    const showAddressFormHandler = () => {
      isAddressSearchContainerVisible.value = true;
      nextTick(() => {
        embedAddressForm(addressSearchContainerEl.value, fillAddressForm);
      });
    };

    const isDeliveryRequestModalOpened = ref(false);
    const isDeliveryRequestMemoVisible = ref(false);
    const deliveryRequestList = [
      "부재 시 경비실에 맡겨주세요",
      "부재 시 택배함에 넣어주세요",
      "부재 시 집앞에 놔주세요",
      "배송 전 연락 바랍니다",
    ];

    const clickDefaultDeliveryRequest = (value: string) => {
      newDeliveryInfo.message = value;
      isDeliveryRequestModalOpened.value = false;
      isDeliveryRequestMemoVisible.value = false;
    };

    const clickDeliveryRequestMemoHandler = () => {
      newDeliveryInfo.message = "";
      isDeliveryRequestModalOpened.value = false;
      isDeliveryRequestMemoVisible.value = true;
    };

    const submitForm = () => {
      if (!isFullData.value) return;
      context.emit("requestChange", newDeliveryInfo);
    };

    watch(
      () => isDeliveryRequestModalOpened.value,
      () => {
        if (isDeliveryRequestModalOpened.value) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      }
    );

    onBeforeUnmount(() => {
      document.body.classList.remove("modal-open");
    });

    return {
      isDeliveryRequestModalOpened,
      isDeliveryRequestMemoVisible,
      deliveryRequestList,
      newDeliveryInfo,
      isFullData,
      addressSearchContainerEl,
      isAddressSearchContainerVisible,

      showAddressFormHandler,
      clickDefaultDeliveryRequest,
      clickDeliveryRequestMemoHandler,
      submitForm,
    };
  },
});
