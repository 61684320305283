





































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  onUnmounted,
  ref,
} from "@vue/composition-api";

// Components
import Modal from "../Common/Modal.vue";

// API
import { requestAuthCode, checkAuthCode } from "@/api/order";

// Hooks
import { useTimer } from "./Hooks/useTimer";
import { formatPhoneNumber, onlyNumber } from "@/utils";

// Library
import axios from "axios";
import { isValidPhoneNumber } from "libphonenumber-js";

type AuthStatus = "INPUTTING" | "PENDING";

export default defineComponent({
  components: { Modal },
  props: {
    shopUrl: {
      type: String,
      required: true,
    },
  },
  emits: ["completePhoneAuth"],
  setup(props, context) {
    const phoneNo = ref("");
    const authCode = ref("");
    const isAuthModalOpen = ref(false);
    const isLoading = ref(false);
    const authStatus = ref<AuthStatus>("INPUTTING");

    const isInputting = computed(() => authStatus.value === "INPUTTING");
    const isValidPhoneNo = computed(() => {
      return isValidPhoneNumber(phoneNo.value, "KR");
    });
    const isValidAuthCode = computed(() => authCode.value.length === 6);

    const { seconds, restart, pause, resume } = useTimer(60, {
      autoStart: false,
    });

    onBeforeMount(() => {
      if (localStorage.getItem("access_token")) {
        context.emit("completePhoneAuth");
      }
    });

    onUnmounted(() => {
      pause();
    });

    const clearInput = () => {
      authCode.value = "";
    };

    const onRequestAuthCode = async (isResend = false) => {
      try {
        resume();
        isLoading.value = true;
        await requestAuthCode(phoneNo.value);
        if (isResend) {
          alert("인증번호가 재발송되었습니다.");
        }
        authStatus.value = "PENDING";
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const code = err.response?.data.code;
          switch (code) {
            case 122:
              alert(
                "이미 인증코드가 발급된 상태입니다. 잠시 후 다시 시도해주세요."
              );
              break;
            case 400:
              alert("잘못된 전화번호 형식입니다.");
              break;
            default:
              alert("알 수 없는 에러입니다.");
          }
        }
      } finally {
        isLoading.value = false;
      }
    };

    const resendAuthCode = async () => {
      clearInput();
      isAuthModalOpen.value = false;
      await onRequestAuthCode(true);
      restart();
    };

    const onInputPhoneNo = (event: Event) => {
      const { value } = event.target as HTMLInputElement;
      phoneNo.value = onlyNumber(value);
    };

    const onCheckCode = async () => {
      if (isLoading.value) return;
      try {
        isLoading.value = true;
        const res = await checkAuthCode(
          phoneNo.value,
          authCode.value,
          props.shopUrl
        );

        if (res.code === 0) {
          pause();
          localStorage.setItem("access_token", res.data.accessToken.token);
          context.emit("completePhoneAuth");
        } else {
          alert("알 수 없는 에러입니다. 관리자에게 문의해주세요");
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const code = err.response?.data.code;
          switch (code) {
            case 300:
              alert("주문한 내역이 존재하지 않습니다.");
              break;
            case 387: // 인증번호 틀림
              isAuthModalOpen.value = true;
              break;
            default:
              alert("알 수 없는 에러입니다. 관리자에게 문의해주세요");
          }
        }
      } finally {
        isLoading.value = false;
      }
    };

    const onSubmit = async () => {
      if (isInputting.value) {
        if (!isValidPhoneNo.value) return;
        await onRequestAuthCode();
      } else {
        if (!isValidAuthCode.value) return;
        await onCheckCode();
      }
    };

    return {
      isAuthModalOpen,
      isValidPhoneNo,
      isValidAuthCode,
      isInputting,
      onInputPhoneNo,
      phoneNo,
      authCode,
      authStatus,
      seconds,
      onRequestAuthCode,
      resendAuthCode,
      onSubmit,
      onCheckCode,
      formatPhoneNumber,
    };
  },
});
