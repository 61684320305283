
























import { defineComponent, ref, reactive } from "@vue/composition-api";

import Lottie from "vue-lottie";

import checkAnimationData from "@/assets/Lottie/CheckAnimation.json";

export default defineComponent({
  components: { Lottie },
  props: { orderNo: { type: String, required: true } },
  emits: ["click:finish"],
  setup() {
    const checkAnimationIcon = ref();

    const checkAnimationOptions = reactive({
      animationData: checkAnimationData,
      loop: false,
    });

    const handleCheckAnimation = (anime: any) => {
      checkAnimationIcon.value = anime;
    };

    return {
      checkAnimationIcon,
      checkAnimationOptions,
      handleCheckAnimation,
    };
  },
});
