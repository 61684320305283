
















































// types
import { DeliveryInfo } from "@/components/Delivery/Types";

// components
import DestinationInfo from "@/components/Delivery/DestinationInfo.vue";
import SweetTracker from "@/components/Delivery/SweetTracker.vue";

import { defineComponent, computed, ref } from "@vue/composition-api";
export default defineComponent({
  components: { DestinationInfo, SweetTracker },
  props: {
    deliveryInfo: {
      type: Object as () => DeliveryInfo,
      required: true,
    },
  },
  setup(props) {
    const shipmentState = computed(() => {
      const state = props.deliveryInfo.shipmentStatus;

      switch (state) {
        case "READY":
          return "배송 준비 중";
        case "DELIVERY":
          return "배송 중";
        case "DONE":
          return "배송 완료";
        default:
          return "-";
      }
    });

    const shipmentCompany = computed(() =>
      props.deliveryInfo.shipmentCompany
        ? props.deliveryInfo.shipmentCompany
        : "-"
    );

    const sweetTrackerEl = ref();

    const submit = () => {
      sweetTrackerEl.value.submit();
    };

    return { shipmentState, shipmentCompany, sweetTrackerEl, submit };
  },
});
