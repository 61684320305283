



































































































































































































import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watchEffect,
} from "@vue/composition-api";
import axios from "axios";

import formatNumber from "@/components/Hooks/formatNumber";
import makeTime from "@/components/Hooks/makeTime";
import makeOrderState from "@/components/Delivery/Hooks/makeOrderState";

import { Order, OrderOption } from "@/components/Delivery/Types";
import { OrderPreviewInfo } from "@/components/Product/Types";

import OrderPreview from "@/components/Common/OrderPreview.vue";
import MainTextInputIcon from "@/components/Common/MainTextInputIcon.vue";
import ArrowDownIcon from "@/assets/ElementsImage/Arrow-Down.vue";
import BottomFixedModal from "@/components/Common/BottomFixedModal.vue";
import CloseIcon from "@/assets/ElementsImage/CloseIcon.vue";
import Modal from "@/components/Common/Modal.vue";
import MainTextInput from "@/components/Common/MainTextInput.vue";
import RefundPolicy from "@/components/Delivery/RefundPolicy.vue";
import LoadingSpinnerBox from "@/components/Common/LoadingSpinnerBox.vue";

export default defineComponent({
  components: {
    OrderPreview,
    MainTextInputIcon,
    ArrowDownIcon,
    BottomFixedModal,
    CloseIcon,
    Modal,
    MainTextInput,
    RefundPolicy,
    LoadingSpinnerBox,
  },
  emits: ["click:cancel", "click:ok"],
  props: {
    order: { type: Object as () => Order, required: true },
    paymentMethod: { type: String, required: true },
    orderGroupNo: { type: String, required: true },
    state: { type: String, required: true },
    shopperName: { type: String, required: true },
  },
  setup(props, context) {
    const locale = context.root.$i18n.locale;
    const orderDate = makeTime("YYYY.MM.DD", props.order.createdAt, locale);

    const makeSelectedOption = (optionText: OrderOption[]) => {
      const previewOptions: string[] = [];
      optionText.forEach((option) => {
        let text = option.content;
        if (option.addPrice) {
          text += `${text}(+${formatNumber(option.addPrice)})`;
        }
        previewOptions.push(text);
      });
      return previewOptions;
    };

    const makeOrderPreviewInfo = (order: Order) => {
      const previewInfo: OrderPreviewInfo = {
        productName: order.productName,
        price: order.amount + order.vat,
        marketPrice: null,
        thumbnailImg: order.productThumbImg,
        quantity: order.qty,
        selectedOptions: makeSelectedOption(order.optionText),
      };
      return previewInfo;
    };

    const cancelReasonLabels = [
      "구매의사 취소",
      "색상 및 사이즈 변경",
      "다른 상품 잘못 주문",
      "서비스 불만족",
      "상품정보 상이",
    ];

    const isReasonModalOpened = ref(false);
    const reasonInputText = ref("");
    const cancelReason = ref("");

    const isCancelCheckModalOpened = ref(false);

    const requestCancel = async (
      orderGroupNo: string,
      orderNoList: string[],
      shopperName: string,
      reason: string,
      bankInfo?: {
        bankHolder: string;
        bankName: string;
        accountNumber: string;
      }
    ) => {
      const snakeBankInfo = bankInfo
        ? {
            bank_no: bankInfo.accountNumber,
            bank_name: bankInfo.bankName,
            bank_holder: bankInfo.bankHolder,
          }
        : {};
      return await axios.post(
        `${process.env.VUE_APP_BACKEND_SERVER}/order/cancel/shp/`,
        {
          order_group_no: orderGroupNo,
          order_no_list: orderNoList,
          name: shopperName,
          reason,
          ...snakeBankInfo,
        }
      );
    };

    const bankHolder = ref("");
    const bankName = ref("");
    const accountNumber = ref("");

    const clickOkBtnHandler = () => {
      if (props.state === "PAID" && props.paymentMethod === "ACCOUNT") {
        if (bankHolder.value.length === 0) {
          alert("예금주명을 입력해주세요.");
          return;
        }

        if (bankName.value.length === 0) {
          alert("은행명을 입력해주세요.");
          return;
        }

        if (accountNumber.value.length === 0) {
          alert("계좌번호를 입력해주세요.");
          return;
        }
      }

      isCancelCheckModalOpened.value = true;
    };

    const cancelLoading = ref(false);

    const cancelOrderHandler = async () => {
      if (cancelLoading.value) {
        return;
      }

      isCancelCheckModalOpened.value = false;
      cancelLoading.value = true;
      try {
        let bankInfo;

        if (props.state === "PAID" && props.paymentMethod === "ACCOUNT") {
          bankInfo = {
            bankHolder: bankHolder.value,
            bankName: bankName.value,
            accountNumber: accountNumber.value,
          };
        }

        await requestCancel(
          props.orderGroupNo,
          [props.order.orderNo],
          props.shopperName,
          cancelReason.value,
          bankInfo
        );
        context.emit("click:ok", props.order);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (!error.response?.data?.data?.naverpay_result) {
            alert("주문 취소에 실패했습니다.");
          } else {
            const code = error.response?.data.code;
            const msg = error.response?.data.data.naverpay_result.msg;
            switch (code) {
              case 501:
                alert(msg);
                break;
              default:
                alert("주문 취소에 실패했습니다.");
            }
          }
        } else {
          alert("주문 취소에 실패했습니다.");
        }
      } finally {
        cancelLoading.value = false;
      }
    };

    const isDirectReasonInputVisible = ref(false);

    const selectCancelReasonHandler = (value: string) => {
      cancelReason.value = value;
      reasonInputText.value = value;
      isReasonModalOpened.value = false;
      isDirectReasonInputVisible.value = false;
    };

    const selectDirectReasonInputHandler = () => {
      isDirectReasonInputVisible.value = true;
      reasonInputText.value = "직접 입력";
      isReasonModalOpened.value = false;
      cancelReason.value = "";
    };

    const inputDirectReasonHandler = (value: string) => {
      cancelReason.value = value;
    };

    const cancelProductPrice = ref(0);
    const cancelShippingFee = ref(0);

    const requestCancelPrice = async (
      orderGroupNo: string,
      orderNoList: string[],
      shopperName: string
    ) => {
      const res = await axios.post(
        `${process.env.VUE_APP_BACKEND_SERVER}/order/cancel-calculate/shp/`,
        {
          order_group_no: orderGroupNo,
          order_no_list: orderNoList,
          name: shopperName,
        }
      );
      const data = res.data.data;
      const formattedData = {
        productPrice: data.amount,
        shippingFee: data.shipping_fee,
      };

      return formattedData;
    };

    onMounted(async () => {
      try {
        const { productPrice, shippingFee } = await requestCancelPrice(
          props.orderGroupNo,
          [props.order.orderNo],
          props.shopperName
        );
        cancelProductPrice.value = productPrice;
        cancelShippingFee.value = shippingFee;
      } catch {
        alert("취소 금액 계산에 실패했습니다.");
      }
    });

    watchEffect(() => {
      if (
        isReasonModalOpened.value ||
        isCancelCheckModalOpened.value ||
        cancelLoading.value
      ) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    });

    onBeforeUnmount(() => {
      document.body.classList.remove("modal-open");
    });

    return {
      orderDate,
      isReasonModalOpened,
      cancelReason,
      cancelReasonLabels,
      reasonInputText,
      isCancelCheckModalOpened,
      isDirectReasonInputVisible,
      cancelProductPrice,
      cancelShippingFee,
      bankHolder,
      bankName,
      accountNumber,
      cancelLoading,

      makeOrderPreviewInfo,
      makeOrderState,
      selectCancelReasonHandler,
      cancelOrderHandler,
      selectDirectReasonInputHandler,
      inputDirectReasonHandler,
      formatNumber,
      clickOkBtnHandler,
    };
  },
});
