import { ref, onUnmounted, onMounted } from "@vue/composition-api";

export const useTimer = (
  expiry = 60,
  options: { autoStart: boolean } = { autoStart: true }
) => {
  const { autoStart } = options;
  let interval: NodeJS.Timer;

  const defaultExpiray = ref(expiry);

  const seconds = ref(defaultExpiray);

  const isActive = ref(autoStart);

  const start = () => {
    if (isActive.value) {
      if (interval) {
        clearInterval(interval);
      }

      interval = setInterval(() => {
        seconds.value = seconds.value - 1;

        if (seconds.value === 0) {
          clearInterval(interval);
          isActive.value = false;
        }
      }, 1000);
    }
  };

  const pause = () => {
    isActive.value = false;
    if (interval) {
      clearInterval(interval);
    }
  };

  const restart = (newExpiry = expiry) => {
    pause();
    defaultExpiray.value = newExpiry;
    seconds.value = defaultExpiray.value;
    isActive.value = true;
    start();
  };

  const resume = () => {
    if (seconds.value === 0) {
      return;
    }
    isActive.value = true;
    start();
  };

  onMounted(() => {
    if (isActive.value) {
      start();
    }
  });

  onUnmounted(() => {
    if (interval) {
      clearInterval(interval);
    }
  });

  return {
    seconds,
    isActive,
    pause,
    restart,
    resume,
  };
};
