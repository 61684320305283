export default function makeOrderState(state: string) {
  switch (state) {
    case "REGISTER":
      return "입금대기 중";
    case "ORDERED":
      return "입금대기 중";
    case "PAID":
      return "결제 완료";
    case "READY":
      return "배송 준비 중";
    case "DELIVERY":
      return "배송 중";
    case "DONE":
      return "배송 완료";
    case "CANCELING":
      return "취소 처리 중";
    case "CANCELED":
      return "취소 완료";
    case "TAKEBACK":
      return "반품 처리 중";
    case "TAKEBACKED":
      return "반품 완료";
    case "EXCHANGE":
      return "교환 처리 중";
    case "EXCHANGED":
      return "교환 완료";
    default:
      return "";
  }
}
