






















































































// types
import {
  OrderInfoByStatus,
  OrderInfoV2,
  Order,
  OrderState,
  PayType,
  CancelledOrderType,
  OrderByProduct,
  ProductByState,
} from "@/components/Delivery/Types";

// components
import ProductList from "./ProductList.vue";

// hooks
import makeTime from "@/components/Hooks/makeTime";
import formatNumber from "@/components/Hooks/formatNumber";
import makeOrderState from "@/components/Delivery/Hooks/makeOrderState";

// utils
import _ from "lodash";
import { formatPhoneNumber } from "@/utils";

import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  components: { ProductList },
  props: {
    orderList: { type: Array as () => OrderInfoV2[], required: true },
  },
  emits: ["deliveryTracking", "destinationChange", "click:cancel"],
  setup(props, context) {
    // props.orderList[].ordersByProduct[].orders[].state = 'ORDERED'
    // 주문 상태별로 그룹핑하기 위해서 데이터 가공
    const orderListByStatus = computed<OrderInfoByStatus[]>(() =>
      props.orderList.reduce((acc: OrderInfoByStatus[], item) => {
        return [
          ...acc,
          {
            ...item,
            ordersByProduct: item.ordersByProduct.reduce(
              (acc: OrderByProduct[], item) => {
                return [
                  ...acc,
                  {
                    productId: item.productId,
                    productsByState: _.groupBy(
                      item.orders,
                      "state"
                    ) as ProductByState,
                  },
                ];
              },
              []
            ),
          },
        ];
      }, [])
    );

    // orderState에 따른 유저 액션 허용 영역 정의(cancel / search / refund / none)
    const makeActionMode = (state: OrderState) => {
      switch (state) {
        case "REGISTER":
          return "cancel";
        case "ORDERED":
          return "cancel";
        case "PAID":
          return "cancel";
        case "READY":
          return "search";
        case "DELIVERY":
          return "search";
        case "DONE":
          return "refund";
        default:
          return "none";
      }
    };

    const makePayType = (type: PayType) => {
      switch (type) {
        case "ACCOUNT":
          return "무통장입금";
        case "CARD":
          return "신용카드";
        case "NAVERPAY_AUTH":
          return "네이버페이";
        default:
          return "기타";
      }
    };

    const getOrderDate = (date: string) => {
      const locale = context.root.$i18n.locale;
      return makeTime("YYYY.MM.DD", date, locale);
    };

    const alertMessage = () => {
      alert("현재 개발 중인 기능입니다.\n채널톡 상담을 이용해주세요.");
    };

    const clickCancelHandler = (payload: CancelledOrderType) => {
      context.emit("click:cancel", payload);
    };

    const clickChangeDeliveryHandler = (order: Order) => {
      context.emit("destinationChange", order);
    };

    const clickDeliveryTracking = (order: Order) => {
      context.emit("deliveryTracking", order);
    };

    return {
      makePayType,
      getOrderDate,
      orderListByStatus,
      makeOrderState,
      formatNumber,
      makeActionMode,
      alertMessage,
      clickCancelHandler,
      clickChangeDeliveryHandler,
      clickDeliveryTracking,
      formatPhoneNumber,
    };
  },
});
