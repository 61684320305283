

















































































// types
import { Shop } from "@/components/Shop/Types";
import {
  Order,
  DeliveryInfo,
  OrderInfoV2,
  DeliveryInfoV2,
  CancelledOrderType,
} from "@/components/Delivery/Types";

// library
import axios from "axios";

// components
import BaseLayout from "@/components/Layout/BaseLayout.vue";
import MyshopInfoLayout from "@/components/Layout/MyshopInfoLayout.vue";
import SmartPhoneLayout from "@/components/Layout/SmartPhoneLayout.vue";
import ShopInfoMain from "@/components/Shop/ShopInfoMain.vue";
import SearchForm from "@/components/Delivery/SearchForm.vue";
import DestinationChange from "@/components/Delivery/DestinationChange.vue";
import DeliveryTracking from "@/components/Delivery/DeliveryTracking.vue";
import LeftArrowIcon from "@/assets/ElementsImage/LeftArrowIcon.vue";
import ChannelTalk from "@/components/ChannelTalk/ChannelTalk.vue";
import BaseFooter from "@/components/Layout/BaseFooter.vue";
import OrderCancel from "@/components/Delivery/OrderCancel.vue";
import OrderCancelSuccess from "@/components/Delivery/OrderCancelSuccess.vue";
import BaseHeader from "@/components/Layout/BaseHeader.vue";
import OrderListV2 from "@/components/Delivery/OrderListV2.vue";

import { defineComponent, ref, computed, watch } from "@vue/composition-api";

// API
import {
  getOrderList,
  requestAddressChange,
  requestDeliveryInfo,
} from "@/api/order";

export type SearchMethod = "ORDER_NO" | "PHONE_NO";

type DeliveryStep =
  | "form"
  | "cancel"
  | "destinationChange"
  | "deliveryTracking"
  | "refund"
  | "exchange"
  | "cancelSuccess"
  | "list";

export default defineComponent({
  components: {
    BaseLayout,
    MyshopInfoLayout,
    SmartPhoneLayout,
    ShopInfoMain,
    SearchForm,
    DestinationChange,
    DeliveryTracking,
    LeftArrowIcon,
    ChannelTalk,
    BaseFooter,
    OrderCancel,
    OrderCancelSuccess,
    BaseHeader,
    OrderListV2,
  },
  props: {
    shopInfo: {
      type: Object as () => Shop,
      required: true,
    },
    givenOrderGroupNo: {
      type: String,
    },
  },
  setup(props) {
    /*
    form -> list
    list <-> cancel / destinationChange / deliveryTracking / refund / exchange / cancelSuccess
    */
    const searchMethod = ref<SearchMethod>("ORDER_NO");
    const step = ref<DeliveryStep>("form");
    const orderGroupNo = ref(props.givenOrderGroupNo ?? "");
    const shopperName = ref("");
    const orderGroupInfo = ref<OrderInfoV2[]>([]);
    const isShowShopHeader = computed(
      () => props.shopInfo && (step.value === "form" || step.value === "list")
    );

    const requestDeliveryList = async () => {
      try {
        const res = await getOrderList(
          props.shopInfo.urlPath,
          searchMethod.value,
          orderGroupNo.value,
          shopperName.value
        );

        return res.data;
      } catch (err) {
        if (axios.isAxiosError(err)) {
          switch (err.response?.data.code) {
            case 100:
              // 토큰값이 유효하지 않은 상황. 별도의 사용자 피드백은 없음
              break;
            case 904:
              alert("주문한 내역이 존재하지 않습니다.");
              break;
            case 908:
              alert("주문번호와 이름이 올바른지 확인해주세요.");
              break;
            case 909:
              alert("스토어가 존재하지 않습니다.");
              break;
            default:
              alert("요청에 실패했습니다. 다시 시도해주세요.");
          }
          throw err;
        }
      }
    };

    const searchDone = async () => {
      try {
        const response = await requestDeliveryList();
        orderGroupInfo.value = response.data.results;
        step.value = "list";
      } catch (err) {
        console.error(err);
      }
    };

    const deliveryInfo = ref<DeliveryInfo | null>(null);
    const targetOrderNo = ref<string | null>(null);

    const getDeliveryInfo = async (orderNo: string) => {
      try {
        const res = await requestDeliveryInfo(orderNo);

        return res.data;
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const code = err.response?.data.code;
          switch (code) {
            case 100:
              alert("인증 정보가 만료되었습니다. 다시 인증해주세요.");
              break;
          }
        }
        return null;
      }
    };

    const changeDeliveryInfoMode = async (order: Order) => {
      const { personalCustomsId } = order;
      const deliveryResponse = await getDeliveryInfo(order.orderNo);

      if (deliveryResponse) {
        step.value = "destinationChange";
        deliveryInfo.value = { ...deliveryResponse, personalCustomsId };
        targetOrderNo.value = order.orderNo;
      }
    };

    const changeDeliveryTrackingMode = async (order: Order) => {
      const deliveryResponse = await getDeliveryInfo(order.orderNo);

      if (deliveryResponse) {
        step.value = "deliveryTracking";
        deliveryInfo.value = {
          ...deliveryResponse,
          shipmentStatus: order.state,
        };
      }
    };

    const requestChange = async (newDeliveryInfo: DeliveryInfoV2) => {
      const payload = {
        order_no: targetOrderNo.value,
        recipient_name: newDeliveryInfo.recipientName,
        zipcode: newDeliveryInfo.zipcode,
        address: newDeliveryInfo.address,
        address_detail: newDeliveryInfo.addressDetail,
        phone: newDeliveryInfo.phone,
        message: newDeliveryInfo.message,
      };

      try {
        await requestAddressChange(payload);
        alert("배송정보 변경에 성공했습니다.");
        deliveryInfo.value = null;
        targetOrderNo.value = null;
        step.value = "list";
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const code = error.response?.data.code;
          switch (code) {
            case 910:
              alert(
                "제주도, 도서산간은 주소변경이 안됩니다.\n고객센터로 문의해주세요."
              );
              break;
            default:
              alert("요청이 실패했습니다. 다시 시도해주세요.");
          }
        } else {
          alert("요청이 실패했습니다. 다시 시도해주세요.");
        }
      }
    };

    const cancelTargetOrder = ref<CancelledOrderType | null>();

    const goBackToList = () => {
      step.value = "list";
      cancelTargetOrder.value = null;
    };

    const changeStep = (value: DeliveryStep) => {
      step.value = value;
    };

    const clickCancelHandler = (order: CancelledOrderType) => {
      cancelTargetOrder.value = order;
      changeStep("cancel");
    };

    const successCancelHandler = async () => {
      changeStep("cancelSuccess");
      const res = await requestDeliveryList();
      if (res && orderGroupInfo.value) {
        orderGroupInfo.value = res.data.results;
      }
    };

    const orderCancelFinishHandler = () => {
      cancelTargetOrder.value = null;
      changeStep("list");
    };

    watch(
      () => step.value,
      async () => {
        const res = await requestDeliveryList();

        if (res && orderGroupInfo.value) {
          orderGroupInfo.value = res.data.results;
        }
      }
    );

    return {
      step,
      orderGroupNo,
      isShowShopHeader,
      deliveryInfo,
      cancelTargetOrder,
      orderGroupInfo,
      shopperName,
      searchMethod,

      searchDone,
      changeDeliveryTrackingMode,
      changeDeliveryInfoMode,
      requestChange,
      goBackToList,
      changeStep,
      clickCancelHandler,
      orderCancelFinishHandler,
      successCancelHandler,
    };
  },
});
