


























import { defineComponent } from "@vue/composition-api";

import useSweetTracker from "@/components/Delivery/Hooks/useSweetTracker";

export default defineComponent({
  props: {
    companyName: {
      type: String,
    },
    trackingNo: {
      type: String,
    },
  },
  setup(props) {
    const { apiKey, sweetTrackerUrl, companyCode, formEl, submit } =
      useSweetTracker(props.companyName);

    return {
      apiKey,
      sweetTrackerUrl,
      companyCode,
      formEl,
      submit,
    };
  },
});
